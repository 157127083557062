import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-set-gate',
  templateUrl: './set-gate.page.html',
  styleUrls: ['./set-gate.page.scss'],
})
export class SetGatePage implements OnInit {
  selectedGate;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalController.dismiss({entryInfo: this.selectedGate});
  }

  async selectGate(form) {
    await this.modalController.dismiss({name: form.value.entryGate});
  }

}
