import { VehiclesService } from '../../../services/vehicles.service';
import { NavParams, ModalController, LoadingController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.page.html',
  styleUrls: ['./add-vehicle.page.scss'],
})
export class AddVehiclePage implements OnInit {

  vehicles = [];
  selectedVehicle = null;
  customer = null;
  constructor(private modalController: ModalController,
    private vehiclesService: VehiclesService,
    private navParams: NavParams,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.getVehicles();
    this.selectedVehicle = this.navParams.get('vehicle');
    this.customer = this.navParams.get('customer');
  }

  async closeModal() {
    await this.modalController.dismiss({vehicle: this.selectedVehicle});
  }

  getVehicles() {
    this.vehiclesService.getVehicles().subscribe(res => {
      this.vehicles = res.vehicles;
    });
  }

  async selectVehicle(vehicle) {
    await this.modalController.dismiss({vehicle: vehicle});
  }

  async saveVehicle(form) {
    let vehicle;
    const that = this;
    const loading = await this.loadingController.create({
      message: 'Loading'
    });
    await loading.present();
    this.vehiclesService.saveVehicle(form.value).subscribe(res => {
      loading.dismiss();
      if (res) {
        const vehicleId = res.vehicle.vehicle_id;
        if (vehicleId) {
          that.vehiclesService.getVehicle(vehicleId).subscribe(result => {
            vehicle = result.vehicles[0];
            this.selectedVehicle = vehicle;
            that.modalController.dismiss({vehicle: vehicle});
          });
          return true;
        }
      }
      this.showAlert({
        header: 'Error',
        message: 'An unexpected error has occurred, please try again!',
        buttons: ['OK']
      });
    });
  }

  async showAlert(options) {
    const alert = await this.alertController.create(options);
    alert.present();
  }

}
