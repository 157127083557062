import { CartService } from '../../../services/cart.service';
import { NavParams, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-item',
  templateUrl: './transaction-item.page.html',
  styleUrls: ['./transaction-item.page.scss'],
})
export class TransactionItemPage implements OnInit {
  productId = null;
  name = null;
  quantity = null;
  total = null;
  price = null;
  totals = {};

  constructor(private navParams: NavParams,
    private modalController: ModalController,
    private cartService: CartService) { }

  ngOnInit() {
    this.productId = this.navParams.get('product_id');
    this.name = this.navParams.get('name');
    this.quantity = this.navParams.get('quantity');
    this.total = this.navParams.get('total');
    this.price = this.navParams.get('price');
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  removeItem() {
    this.cartService.removeProduct(this.productId);
    this.modalController.dismiss();
  }

  subItemQuantity() {
    this.quantity -= 1;
    this.total = this.price * this.quantity;
    this.cartService.updateItemQuantity(this.productId, this.quantity);
    this.totals = this.cartService.getTotals();
  }

  addItemQuantity() {
    this.quantity += 1;
    this.total = this.price * this.quantity;
    this.cartService.updateItemQuantity(this.productId, this.quantity);
    this.totals = this.cartService.getTotals();
  }

}
