import { TransactionsService } from '../../../services/transactions.service';
import { Router } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-payment',
  templateUrl: './card-payment.page.html',
  styleUrls: ['./card-payment.page.scss'],
})
export class CardPaymentPage implements OnInit {

  constructor(private navParams: NavParams,
    private router: Router,
    private modalController: ModalController,
    private transactionsService: TransactionsService) { }

  ngOnInit() {
    console.log(this.navParams.get('transaction'));
  }

  closeModal() {
    this.modalController.dismiss();
  }

  process(form) {}

}
