import { Component, OnInit, NgModule } from '@angular/core';
import { LoadingController,  NavController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import * as papa from 'papaparse';

@Component({
  selector: 'app-import-export-products',
  templateUrl: './import-export-products.page.html',
  styleUrls: ['./import-export-products.page.scss'],
})
export class ImportExportProductsPage implements OnInit {
  public products: any;
   csvData: any[] = [];
   headerRow: any[] = [];
   file_path = '';

  constructor(public http: HttpClient,
    public navCtrl: NavController,
    private modalController: ModalController ) {
     }

  ngOnInit() {
  }
  importProduct() { }

  private extractData(res) {
      let csvData;
      let parseData;
      csvData = res['_body'] || '';
      parseData = papa.parse(csvData).data;

      this.headerRow = parseData[0];

      parseData.splice(0, 1);
      this.csvData = parseData;
      console.log(this.headerRow);
  }

  downloadCSV() {
    let csv;
    let blob;
    let a;
    csv = papa.unparse({
      fields: this.headerRow,
      data: this.csvData
    });

    blob = new blob([csv]);
    a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'poducts.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  private handleError(err) {
      console.log(err);
  }

}
