import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Constants } from './constant';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  protected headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient) { }

  getVehicles(): Observable<any> {
    return this.http.get(Constants.API_URL + 'vehicles');
  }

  getVehicle(vehicle_id): Observable<any> {
    return this.http.get(Constants.API_URL + 'vehicles/' + vehicle_id);
  }

  saveVehicle(vehicle): Observable<any> {
    return this.http.post(Constants.API_URL + 'vehicles', vehicle);
  }

  searchVehicle(regNum): Observable<any> {
    return this.http.get(Constants.API_URL + 'vehicles/search/reg_number/' + regNum);
  }
}
