import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { VehiclesService } from './../services/vehicles.service';
import { TransactionsService } from './../services/transactions.service';
import { ReportsService } from './../services/reports.service';
import { ProductsService } from './../services/products.service';
import { CustomersService } from './../services/customers.service';
import { CartService } from './../services/cart.service';
import { AuthService } from './../services/auth.service';
import { CardPaymentPageModule } from './modals/card-payment/card-payment.module';
import { AddVehiclePageModule } from './modals/add-vehicle/add-vehicle.module';
import { AddCustomerPageModule } from './modals/add-customer/add-customer.module';
import { SetGatePageModule } from './modals/set-gate/set-gate.module';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule } from '@angular/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TransactionItemPageModule } from './modals/transaction-item/transaction-item.module';
import { TicketPageModule } from './modals/ticket/ticket.module';
import { IonicSelectableModule } from 'ionic-selectable';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { ImportExportProductsPageModule } from './modals/import-export-products/import-export-products.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RePrintPageModule } from './modals/re-print/re-print.module';
import { Printer } from '@ionic-native/printer/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    NgxDatatableModule,
    Ionic4DatepickerModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TransactionItemPageModule,
    AddCustomerPageModule,
    SetGatePageModule,
    AddVehiclePageModule,
    TicketPageModule,
    IonicSelectableModule,
    ImportExportProductsPageModule,
    CardPaymentPageModule,
    RePrintPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    CartService,
    CustomersService,
    ProductsService,
    ReportsService,
    TransactionsService,
    VehiclesService,
    Printer,
    NetworkInterface,
    Network
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
