import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Constants } from './constant';
import { constants } from 'perf_hooks';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getProducts(): Observable<any> {
    return this.http.get(Constants.API_URL + 'products');
  }

  importProduct(): Observable<any> {
    return  this.http.get(Constants.API_URL + 'import');
  }
}
