import { CustomersService } from '../../../services/customers.service';
import { NavParams, ModalController, LoadingController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.page.html',
  styleUrls: ['./add-customer.page.scss'],
})
export class AddCustomerPage implements OnInit {

  customers = [];
  selectedCustomer = null;
  firstname = '';
  lastname = '';
  email = '';
  telephone = '';
  id_type = '';
  id_number = '';
  customer_group_id = 1;
  constructor(private modalController: ModalController,
    private customersService: CustomersService,
    private navParams: NavParams,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.getCustomers();
    this.selectedCustomer = this.navParams.get('customer');
  }

  async closeModal() {
    await this.modalController.dismiss({customer: this.selectedCustomer});
  }

  getCustomers() {
    this.customersService.getCustomers().subscribe(res => {
      this.customers = res.customers;
    });
  }

  async selectCustomer(customer) {
    await this.modalController.dismiss({customer: customer});
  }

  async saveCustomer(form, refresher?) {
    let customer;
    const that = this;
    const loading = await this.loadingController.create({
      message: 'Loading'
    });
    await loading.present();
    this.customersService.addCustomer(form.value).subscribe(res => {
      loading.dismiss();
      if (res) {
        if (typeof res.customer !== 'undefined') {
          const customerId = res.customer.customer_id;
          if (customerId) {
            that.customersService.getCustomer(customerId, true).subscribe(result => {
              if (result) {
                if (typeof result.customers !== 'undefined') {
                  customer = result.customers[0];
                  this.selectedCustomer = customer;
                  that.modalController.dismiss({customer: customer});
                  if (refresher) {
                    refresher.target.complete();
                  }
                }
              }
            });
            return true;
          }
        }
      }
      this.showAlert({
        header: 'Error',
        message: 'An unexpected error has occurred, please try again!',
        buttons: ['OK']
      });
    });
  }

  async showAlert(options) {
    const alert = await this.alertController.create(options);
    alert.present();
  }

}
