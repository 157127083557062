import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Constants } from './constant';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getReports(): Observable<any> {
    return this.http.get(Constants.API_URL + 'reports');
  }

  getReportById(report_id, start_date, end_date): Observable<any> {
    return this.http.get(`${Constants.API_URL}reports/${report_id}?start_date=${start_date}&end_date=${end_date}`);
  }
}
