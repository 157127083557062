import { tap, map, catchError } from 'rxjs/operators';
import { OfflineManagerService } from './offline-manager.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Constants } from './constant';
import { Storage } from '@ionic/storage';

const API_STORAGE_KEY = 'specialkey';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  private postData;
  constructor(private http: HttpClient,
    private networkService: NetworkService,
    private storage: Storage,
    private offlineManager: OfflineManagerService
  ) { }

  getCustomers(forceRefresh: boolean = false): Observable<any> {
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('customers'));
    } else {
      // Return real API data and store it locally
      return this.http.get(Constants.API_URL + 'customers').pipe(
        map(res => res['customers']),
        tap(res => {
          this.setLocalData('customers', res);
        })
      );
    }
  }

  getCustomer(customer_id, forceRefresh: boolean = false): Observable<any> {
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      console.log('offline');
      return from(this.getLocalData('customers/' + customer_id));
    } else {
      // Return real API data and store it locally
      console.log(customer_id);
      return this.http.get(Constants.API_URL + 'customers/' + customer_id).pipe(
        tap(res => {
          this.setLocalData('customers', res);
        })
      );
    }
  }

  addCustomer(customer): Observable<any> {
    let url;
    url = `${Constants.API_URL}customers`;
    this.postData = customer;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      return from(this.offlineManager.storeRequest(url, 'POST', customer));
    } else {
      return this.http.post(url, customer).pipe(
        catchError(this.handleError('customers', []))
      );
    }
    // return this.http.post(Constants.API_URL + 'customers', customer);
  }

  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status === 400 && error.statusText === 'Bad Request') {
        return of(result as T);
      } else {
        // TODO: send the error to remote logging infrastructure
        // console.error(error); // log to console instead

        // Store into local storage
        this.offlineManager.storeRequest(Constants.API_URL, 'PUT', this.postData);

        // TODO: better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
      }
    };
  }
}
