import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule'
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'products',
    canActivate: [AuthGuard],
    loadChildren: './products/products.module#ProductsPageModule'
  },
  {
    path: 'transaction',
    canActivate: [AuthGuard],
    loadChildren: './transaction/transaction.module#TransactionPageModule'
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: './reports/reports.module#ReportsPageModule'
  },
  {
    path: 'payment',
    canActivate: [AuthGuard],
    loadChildren: './payment/payment.module#PaymentPageModule'
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    loadChildren: './customers/customers.module#CustomersPageModule'
  },
  {
    path: 'vehicles',
    canActivate: [AuthGuard],
    loadChildren: './vehicles/vehicles.module#VehiclesPageModule'
  },
  {
    path: 'reprint/:id',
    canActivate: [AuthGuard],
    loadChildren: './reprint/reprint.module#ReprintPageModule'
  },
  {
    path: 'transaction-item',
    canActivate: [AuthGuard],
    loadChildren: './modals/transaction-item/transaction-item.module#TransactionItemPageModule'
  },
  {
    path: 'add-customer',
    canActivate: [AuthGuard],
    loadChildren: './modals/add-customer/add-customer.module#AddCustomerPageModule'
  },
  {
    path: 'add-vehicle',
    canActivate: [AuthGuard],
    loadChildren: './modals/add-vehicle/add-vehicle.module#AddVehiclePageModule'
  },
  {
    path: 'ticket',
    canActivate: [AuthGuard],
    loadChildren: './modals/ticket/ticket.module#TicketPageModule'
  },
  {
    path: 'transactions',
    canActivate: [AuthGuard],
    loadChildren: './transactions/transactions.module#TransactionsPageModule'
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: './profile/profile.module#ProfilePageModule'
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    loadChildren: './help/help.module#HelpPageModule'
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: './settings/settings.module#SettingsPageModule'
  },
  {
    path: 'import-export-products',
    canActivate: [AuthGuard],
    loadChildren: './modals/import-export-products/import-export-products.module#ImportExportProductsPageModule'
  },
  {
    path: 'reports/:id',
    canActivate: [AuthGuard],
    loadChildren: './report-details/report-details.module#ReportDetailsPageModule'
  },
  {
    path: 'card-payment',
    canActivate: [AuthGuard],
    loadChildren: './modals/card-payment/card-payment.module#CardPaymentPageModule'
  },
  {
    path: 're-print',
    loadChildren: './modals/re-print/re-print.module#RePrintPageModule'
  },
  {
    path: 'ticket/:id',
    loadChildren: './ticket/ticket.module#TicketPageModule'
  },
  { path: 'set-gate', loadChildren: './modals/set-gate/set-gate.module#SetGatePageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
