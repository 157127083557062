import { Platform, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { tap, catchError} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Constants } from './constant';

const TOKEN_KEY = 'x-auth-token';
const USER_AUTH_KEY = 'x-auth-user';
const ENTRY_GATE_KEY = 'entryInfo';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = null;
  authenticationState = new BehaviorSubject(false);
  constructor(private http: HttpClient,
    private storage: Storage,
    private platform: Platform,
    private alertController: AlertController
  ) {
    this.platform.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    });
  }

  login(credentials) {
    return this.http.post(Constants.API_URL + 'users/login', credentials)
      .pipe(
        tap(res => {
          if (res['status'] === 200) {
            this.storage.set(TOKEN_KEY, res['auth']['token']);
            this.user = res['data'];
            this.storage.set(USER_AUTH_KEY, this.user);
            this.authenticationState.next(true);
          } else {
            this.showAlert(res['message']);
          }
        }),
        catchError(e => {
          this.showAlert(e.error.msg);
          throw new Error(e);
        })
      );
  }

  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
      this.storage.remove(USER_AUTH_KEY);
      this.storage.remove(ENTRY_GATE_KEY);
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  async getToken() {
    let token;
    await this.storage.get(TOKEN_KEY).then((val) => {
      if (val) {
        token = val;
      } else {
        token = null;
      }
    });
    return token;
  }

  async getUserInfo() {
    if (this.user === null) {
      await this.storage.get(USER_AUTH_KEY).then((val) => {
        if (val) {
          this.user = val;
        } else {
          this.user = false;
        }
      });
      return this.user;
    } else {
      return this.user;
    }
  }

  clearUserInfo() {
    this.user = null;
  }

  async showAlert(msg) {
    const alert = await this.alertController.create({
      message: msg,
      header: 'Error',
      buttons: ['OK']
    });
    alert.present();
  }
}
