import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { TransactionsService } from './../../../services/transactions.service';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-re-print',
  templateUrl: './re-print.page.html',
  styleUrls: ['./re-print.page.scss'],
})
export class RePrintPage implements OnInit {
  constructor(private modalController: ModalController,
    private loadingController: LoadingController,
    private transactionsService: TransactionsService,
    private alertController: AlertController,
    private router: Router,
    private storage: Storage
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async findTransaction(form) {
    const that = this;
    const loading = await this.loadingController.create({
      message: 'Loading'
    });
    await loading.present();
    if (form.value.invoice_number) {
      this.transactionsService.findTransactionByInvoice(form.value.invoice_number).subscribe(res => {
        loading.dismiss();
        if (res) {
          if (res['status'] === 200) {
            if (res['transactions'].length) {
              let transaction;
              transaction = {
                transaction: res['transactions'][0],
                products: res['products'],
                totals: res['totals']
              };
              this.storage.set('transaction', transaction);
              that.router.navigateByUrl('/ticket/' + res['transactions'][0].transaction_id);
              that.modalController.dismiss();
              return;
            } else {
              that.showAlert({
                header: 'Error',
                subHeader: 'Not Found',
                message: 'Transaction not found, re-enter a correct invoice number.',
                buttons: ['OK']
              });
              return;
            }
          }
        }
        that.showAlert({
          header: 'Error',
          subHeader: 'Not Found',
          message: 'An unexpected error has occured, please try again later or contact administrator.',
          buttons: ['OK']
        });
      });
    } else {
      loading.dismiss();
      this.showAlert({
        header: 'Validation Error',
        subHeader: 'Invalid Invoice Number',
        message: 'Please enter a valid invoice number!',
        buttons: ['OK']
      });
    }
  }

  async showAlert(options) {
    const alert = await this.alertController.create(options);
    alert.present();
  }

}
