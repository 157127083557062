import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Constants } from './constant';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private http: HttpClient) { }

  getTransactions(): Observable<any> {
    return this.http.get(Constants.API_URL + 'transactions');
  }

  getTransaction(transaction_id): Observable<any> {
    return this.http.get(Constants.API_URL + 'transactions/' + transaction_id);
  }

  completeTransaction(transaction) {
    return this.http.post(Constants.API_URL + 'transactions', transaction);
  }

  findTransactionByInvoice(invoiceNumber) {
    return this.http.get(Constants.API_URL + 'transactions/search/invoice_number/' + invoiceNumber.trim());
  }
}
