import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Constants } from './constant';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private data = [
    {
      id: 1,
      title: 'Product Item #1',
      price: 'R 210.00'
    },
    {
      id: 2,
      title: 'Product Item #2',
      price: 'R 210.00'
    }
  ];
  private cart = [];
  private totals = {
    item_qty: 0,
    discount: 0,
    subtotal: 0,
    tax: 0,
    payments: 0,
    total: 0
  };

  constructor(private http: HttpClient) { }

  getItems() {
    return this.data;
  }

  getCart() {
    return this.cart;
  }

  addProduct(product) {
    let exists = false;
    if (!this.cart) {
      let items = [];
      items = JSON.parse(localStorage.getItem('cart'));
      if (items) {
        this.cart = items;
      }
    }
    this.cart.forEach(el => {
      if (el.product_id === product.product_id) {
        el.quantity += 1;
        el.total = el.quantity * el.price;
        exists = true;
        return false;
      }
    });
    if (!exists) {
      product.quantity = 1;
      product.total = product.quantity * product.price;
      this.cart.push(product);
    }
    localStorage.setItem('cart_totals', JSON.stringify(this.totals));
  }

  removeProduct(id) {
    console.log('remove item #' + id);
  }

  updateItemQuantity(id, qty) {
    this.cart.forEach(el => {
      if (el.product_id === id) {
        el.quantity = qty;
        el.total = el.quantity * el.price;
        return false;
      }
    });
    this.refereshTotals();
  }

  saveCart() {
    localStorage.setItem('cart', JSON.stringify(this.cart));
    localStorage.setItem('cart_totals', JSON.stringify(this.totals));
  }

  clearCart() {
    this.cart = [];
    localStorage.removeItem('payment_method');
    localStorage.removeItem('cart');
    localStorage.removeItem('cart_totals');
    localStorage.removeItem('customer');
    this.totals = {
      item_qty: 0,
      discount: 0,
      subtotal: 0,
      tax: 0,
      payments: 0,
      total: 0
    };
  }

  setPaymentMethod(method) {
    localStorage.setItem('payment_method', method);
  }

  setCustomer(customer) {
    localStorage.setItem('customer', JSON.stringify(customer));
  }

  getCustomer() {
    return JSON.parse(localStorage.getItem('customer'));
  }

  refereshTotals() {
    let itemQty;
    let discount;
    let subTotal;
    let tax;
    let payments;
    let amtDue;
    itemQty = 0;
    discount = 0;
    subTotal = 0;
    tax = 0;
    payments = 0;
    amtDue = 0;

    this.cart.forEach(el => {
      itemQty += el.quantity;
      subTotal += el.total;
      tax += el.tax;
      amtDue += el.total + el.tax;
    });

    this.totals = {
      item_qty: itemQty,
      discount: discount,
      subtotal: subTotal,
      tax: tax,
      payments: payments,
      total: amtDue
    };
  }

  getTotals() {
    let itemQty;
    let discount;
    let subTotal;
    let tax;
    let payments;
    let amtDue;
    itemQty = 0;
    discount = 0;
    subTotal = 0;
    tax = 0;
    payments = 0;
    amtDue = 0;

    this.cart.forEach(el => {
      itemQty += el.quantity;
      subTotal += el.total;
      tax += el.tax;
      amtDue += el.total + el.tax;
    });

    this.totals = {
      item_qty: itemQty,
      discount: discount,
      subtotal: subTotal,
      tax: tax,
      payments: payments,
      total: amtDue
    };
    return this.totals;
  }
}
