import { NetworkService, ConnectionStatus } from '../services/network.service';
import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OfflineManagerService } from '../services/offline-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'dashboard',
      icon_type: 'material-icon'
    },
    {
      title: 'Products',
      url: '/products',
      icon: 'library_books',
      icon_type: 'material-icon'
    },
    {
      title: 'New Transaction',
      url: '/transaction',
      icon: 'add_shopping_cart',
      icon_type: 'material-icon'
    },
    {
      title: 'Transactions',
      url: '/transactions',
      icon: 'list',
      icon_type: 'material-icon'
    },
    {
      title: 'Reports',
      url: '/reports',
      icon: 'show_chart',
      icon_type: 'material-icon'
    },
    {
      title: 'Customers',
      url: '/customers',
      icon: 'people',
      icon_type: 'material-icon'
    },
    {
      title: 'Vehicles',
      url: '/vehicles',
      icon: 'directions_car',
      icon_type: 'material-icon'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private auth: AuthService,
    private router: Router,
    private offlineManager: OfflineManagerService,
    private networkService: NetworkService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.auth.authenticationState.subscribe(state => {
        if (state) {
          this.router.navigate(['home']);
        } else {
          this.router.navigate(['login']);
        }
      });

      this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
        if (status === ConnectionStatus.Online) {
          this.offlineManager.checkForEvents().subscribe();
        }
      });
    });
  }
}
